import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import UriHelper from '../../../lib/uri-helper';
import { actionSetSelectedSearchTerm } from '../../../store/Map/MapSelection/actions';
import { selectSelectedSearchTerm } from '../../../store/Map/MapSelection/selectors';
import { selectActiveAllMap, selectActiveAllMapIsFetching } from '../../../store/Map/SuperMap/selectors';
import { PulseLoader } from '../../Shared/pulse-loader';
import DiscoverNavBar from './discover-navbar';
import SearchResults from './Search/search-results';
import MobileMapContainer from '../Map/mobile-map-container';
import { actionActiveMapCleared } from '../../../store/Map/SuperMap/actions';
import {
    actionSentinelClearFeatures,
    actionSentinelResetSelectedAOI,
    actionSentinelResetSelectedFeature,
} from '../../../store/Map/Sentinel/actions';

const Discover = () => {
    const dispatch = useDispatch();
    const [searchKeyword, setSearchKeyword] = useState<string>('');
    const selectedSearchTerm = useSelector(selectSelectedSearchTerm);

    const setSelectedSearchTerm = (searchTerm: string | undefined) => dispatch(actionSetSelectedSearchTerm(searchTerm));

    const activeMap = useSelector(selectActiveAllMap);
    const activeMapIsFetching = useSelector(selectActiveAllMapIsFetching);

    useEffect(() => {
        if (activeMap === undefined) {
            UriHelper.replacePath(`/maps`);
        }
    }, [activeMap]);

    useEffect(() => {
        // clear active map and satellite feature (if there's one) on load of the Discover component
        dispatch(actionActiveMapCleared());
        dispatch(actionSentinelResetSelectedFeature());
        dispatch(actionSentinelClearFeatures());
        dispatch(actionSentinelResetSelectedAOI());

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <MobileExploreContainer data-testid="mobile-explore">
            {activeMapIsFetching ? (
                <MobileActiveMapLoading>
                    <PulseLoader />
                </MobileActiveMapLoading>
            ) : null}
            {activeMap ? (
                <MobileGridMapViewer>
                    <MobileMapContainer tabBarState="discover" />
                </MobileGridMapViewer>
            ) : null}
            <DiscoverNavBar
                selectedSearchTerm={selectedSearchTerm || ''}
                setSelectedSearchTerm={setSelectedSearchTerm}
                searchKeyword={searchKeyword}
                setSearchKeyword={setSearchKeyword}
            />
            <SearchResults selectedSearchTerm={selectedSearchTerm || ''} />
        </MobileExploreContainer>
    );
};

export default Discover;

const MobileExploreContainer = styled.div`
    display: flex;
    flex-direction: column;
    background-color: rgb(34, 34, 34);
    width: 100dvw;
    height: calc(100dvh - 65px); // 100dvh - navbar height - search bar height
`;

const MobileGridMapViewer = styled.div`
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 9999;
`;

const MobileActiveMapLoading = styled.div`
    position: absolute;
    margin: auto;
    top: 50%;
    z-index: 9999;
    width: 100%;
`;
