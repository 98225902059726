import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import moment from 'moment';

import { StoaryResponse } from '../../../../api/stoaryModel';

import Analytics from '../../../../lib/user-analytics';
import UriHelper from '../../../../lib/uri-helper';

import { ListingTabPanelEmpty, ListingTabPanelLoading } from '../Shared/panels';
import MobileProfileStoariesControl from './stoaries-control';
import ProjectPermissionsModal from '../../../MapView/Annotations/Project/Permissions/project-permissions-modal';
import DeleteModal from '../../../Shared/Modal/delete';
import { selectIsDeletingDrawProject } from '../../../../store/Map/DrawProject/selectors';
import { actionDeleteStoary } from '../../../../store/Map/DrawProject/actions';
import { shareLink } from '../../mobile-utils';

interface MobileProfileStoariesProps {
    stoariesLoading: boolean;
    stoaries: StoaryResponse[] | undefined;
    updateStoaries?: () => void;
}

const MobileProfileStoaries = (props: MobileProfileStoariesProps) => {
    const dispatch = useDispatch();

    const [selectedStoary, setSelectedStoary] = useState<StoaryResponse | undefined>();
    const [showPermissionsModal, setShowPermissionsModal] = useState<boolean>(false);
    const [confirmDelete, setConfirmDelete] = useState<boolean>(false);

    const isDeletingProject = useSelector(selectIsDeletingDrawProject);

    const handleStoaryOwner = (stoary: StoaryResponse) => {
        Analytics.Event('Mobile Profile', 'Clicked to view Stoary owner', stoary.ownerId);
        UriHelper.navigateToProfile(stoary.ownerId);
    };

    const handleOpenStoary = (stoaryId: number) => {
        Analytics.Event('Mobile Profile', 'Clicked to view Stoary', stoaryId);
        UriHelper.navigateToStoary(`${stoaryId}`);
    };

    const handleShareStoary = (stoaryId: number) => {
        const shareStoaryLink = `${window.location.origin}/draw/${stoaryId}`;

        shareLink(shareStoaryLink, 'Share your Soar Stoary', 'Check out my Stoary on Soar.Earth!').then(() => {
            Analytics.Event('Mobile Profile', 'Clicked to share Stoary', stoaryId);
        });
    };

    // enable when Stoaries are editable in mobile
    // const handleEditStoary = () => {};

    const handlePermissionsStoary = (stoary: StoaryResponse) => {
        setSelectedStoary(stoary);
        setShowPermissionsModal(true);
    };

    const onPermissionsUpdate = () => {
        Analytics.Event('Mobile Profile', 'Clicked to update Stoary permissions', selectedStoary?.id);

        props.updateStoaries && props.updateStoaries();
        setSelectedStoary(undefined);
        setShowPermissionsModal(false);
    };

    const handleDeleteStoary = (stoary: StoaryResponse) => {
        setSelectedStoary(stoary);
        setConfirmDelete(true);
    };

    const deleteStoary = async () => {
        if (!selectedStoary) {
            return;
        }

        Analytics.Event('Mobile Profile', 'Clicked to delete Stoary', selectedStoary.id);

        await dispatch(actionDeleteStoary(selectedStoary.id));

        setSelectedStoary(undefined);
        setConfirmDelete(false);
    };

    if (props.stoariesLoading) {
        return <ListingTabPanelLoading />;
    }

    if (props.stoaries) {
        if (props.stoaries.length === 0) {
            return (
                <ListingTabPanelEmpty
                    iconUrl={`/assets/stoaries-icons/draw-project-yellow-cropped.svg`}
                    message="It looks like your Stoaries are empty"
                />
            );
        }

        return (
            <React.Fragment>
                {props.stoaries.map((stoary, index) => (
                    <StoaryItem key={index}>
                        <StoaryItemDetails>
                            <StoaryDetails>
                                <StoaryTitle onClick={() => handleOpenStoary(stoary.id)}>{stoary.title}</StoaryTitle>
                                {stoary.sharedAt && (
                                    <StoarySharedBy onClick={() => handleStoaryOwner(stoary)}>
                                        {stoary.ownerName}
                                    </StoarySharedBy>
                                )}
                            </StoaryDetails>
                            <StoaryDate>{moment(stoary.updatedAt * 1000).fromNow()}</StoaryDate>
                        </StoaryItemDetails>
                        <StoaryItemFooter>
                            <MobileProfileStoariesControl
                                stoary={stoary}
                                onOpen={() => handleOpenStoary(stoary.id)}
                                onShare={() => handleShareStoary(stoary.id)}
                                // onEdit={handleEditStoary} // enable when Stoaries are editable in mobile
                                onPermissions={() => handlePermissionsStoary(stoary)}
                                onDelete={() => handleDeleteStoary(stoary)}
                            />
                        </StoaryItemFooter>
                    </StoaryItem>
                ))}
                {showPermissionsModal && selectedStoary && (
                    <ProjectPermissionsModal
                        isOpen={true}
                        toggle={() => {
                            setShowPermissionsModal(false);
                            setSelectedStoary(undefined);
                        }}
                        onInvalidateProjects={onPermissionsUpdate}
                        drawProject={selectedStoary}
                        invitedUserEmails={
                            selectedStoary.sharePermissions ? selectedStoary.sharePermissions.map((p) => p.email) : []
                        }
                    />
                )}
                {confirmDelete && selectedStoary && (
                    <DeleteModal
                        isOpen={true}
                        title="Delete Stoary"
                        message="Are you sure you want to delete this Stoary?"
                        onToggle={() => setConfirmDelete(false)}
                        onDelete={() => deleteStoary()}
                        isDeleting={isDeletingProject}
                    />
                )}
            </React.Fragment>
        );
    }

    return <React.Fragment />;
};

export default MobileProfileStoaries;

const StoaryItem = styled.div`
    display: flex;
    flex-direction: column;

    margin: 5px 10px;
    padding: 10px;

    border: 1px solid ${(props) => props.theme.color.lightGray};
    border-radius: 4px;

    color: ${(props) => props.theme.color.white};
`;

const StoaryItemDetails = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    gap: 16px;
`;

const StoaryDetails = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    flex: 1 0 calc(100% - 120px);
    position: relative;

    min-height: 80px;
`;

const StoaryTitle = styled.h4`
    color: ${(props) => props.theme.color.white};
    text-align: left;

    margin: 0;

    width: 100%;

    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;

    word-break: break-all;
`;
const StoarySharedBy = styled.a`
    color: ${(props) => props.theme.color.lightGray};
    font-size: ${(props) => props.theme.fontSize.small};
    font-weight: 700;
    text-decoration: none;

    &:before {
        content: 'Shared by: ';
        font-weight: 400;
    }
`;

const StoaryDate = styled.p`
    color: ${(props) => props.theme.color.lightGray};
    text-align: left;
    flex: 0 1 auto;
`;
const StoaryItemFooter = styled.div`
    position: relative;

    .stoary-control {
        position: absolute;
        right: 0;
        bottom: 0;
    }
`;
