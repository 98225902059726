import React, { useEffect, useState } from 'react';
import { LoginModalMode } from '../../../Registration/login-enum';
import MobileLogin from './mobile-login';
import MobileRegister from './mobile-register';
import MobileForgotPassword from './mobile-forget-password';
import MobilePasswordReset from './mobile-password-reset';

const MobileAccess = () => {
    const [accessMode, setAccessMode] = useState<LoginModalMode | undefined>(LoginModalMode.LOGIN);
    const isPasswordReset = window.location.pathname === '/password_reset';

    useEffect(() => {
        if (isPasswordReset) {
            setAccessMode(LoginModalMode.PASSWORD_RESET);
        }
    }, [isPasswordReset]);

    switch (accessMode) {
        case LoginModalMode.LOGIN:
            return (
                <MobileLogin
                    onRegister={() => setAccessMode(LoginModalMode.REGISTER)}
                    onForgotPassword={() => setAccessMode(LoginModalMode.FORGOT_PASSWORD)}
                />
            );
        case LoginModalMode.REGISTER:
            return <MobileRegister onLogin={() => setAccessMode(LoginModalMode.LOGIN)} />;
        case LoginModalMode.FORGOT_PASSWORD:
            return <MobileForgotPassword onLogin={() => setAccessMode(LoginModalMode.LOGIN)} />;
        case LoginModalMode.PASSWORD_RESET:
            return <MobilePasswordReset onLogin={() => setAccessMode(LoginModalMode.LOGIN)} />;
    }

    return <React.Fragment />;
};

export default MobileAccess;
