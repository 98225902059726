import { ReactNode } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import Masonry from 'react-masonry-css';
import styled from 'styled-components';
import { ListingDTO } from '../../../../api/model';
import { PulseLoader } from '../../../Shared/pulse-loader';
import SearchResultMasonaryCard from './search-result-masonry-card';
import { useSelector } from 'react-redux';
import { selectSelectedSearchTerm } from '../../../../store/Map/MapSelection/selectors';

const masonryBreakpoints = {
    default: 6,
    1100: 4,
    600: 2,
};

interface SearchResultsMasonaryProps {
    maybeLoadMore: () => void;
    items: ListingDTO[];
    isNoItems: boolean;
    children?: ReactNode;
    resultsContainer?: string;
}

const SearchResultsMasonary = (props: SearchResultsMasonaryProps) => {
    const selectedSearchTerm = useSelector(selectSelectedSearchTerm);

    const selectableListings: ListingDTO[] = props.items.filter((value, index, array) => {
        return array.indexOf(value) === index;
    });

    // NOTE: If you uncomment the props.children the original "current events and trending" section will show up again on the empty view
    return (
        <MobileInfiniteScroll
            dataLength={selectableListings.length}
            next={props.maybeLoadMore}
            height={
                !props.resultsContainer ? `calc(100dvh - ${selectedSearchTerm?.length ? '120px' : '55px'})` : undefined
            }
            hasMore={!props.isNoItems}
            loader={<PulseLoader />}
            endMessage={
                <AllLoadedNotification>{`We have found ${selectableListings.length} results`}</AllLoadedNotification>
            }
            scrollableTarget={props.resultsContainer}
        >
            {props.children}
            {selectableListings ? (
                <MobileMasonry
                    breakpointCols={masonryBreakpoints}
                    className="masonry-grid"
                    columnClassName="masonry-grid_column"
                >
                    {selectableListings.map((listing, index) => (
                        <SearchResultMasonaryCard key={index} listing={listing} />
                    ))}
                </MobileMasonry>
            ) : null}
        </MobileInfiniteScroll>
    );
};

export default SearchResultsMasonary;

const AllLoadedNotification = styled.div`
    text-align: center;
    color: #eed926;
    margin: 10px 0px;
    font-size: 18px;
`;

const MobileInfiniteScroll = styled(InfiniteScroll)`
    &.infinite-scroll-component {
        scroll-behavior: smooth; /* Add smooth scrolling to InfiniteScroll */
    }
`;

const MobileMasonry = styled(Masonry)`
    &.masonry-grid {
        display: -webkit-box; /* Not needed if autoprefixing */
        display: -ms-flexbox; /* Not needed if autoprefixing */
        display: flex;
        width: auto;

        height: calc(100vh - 55px);
    }

    &.masonry-grid_column {
        background-clip: padding-box;
        will-change: transform; /* Enable hardware acceleration for smoother animations */
    }
`;
