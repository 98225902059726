import { LatLng, LatLngBounds } from 'leaflet';
import React, { useCallback, useEffect } from 'react';
import { useMap } from 'react-leaflet';
import { useDispatch, useSelector } from 'react-redux';
import Analytics from '../../../lib/user-analytics';
import { actionSetMapBounds, actionSetMapMoveEnd, actionSetMapZoom } from '../../../store/App/actions';
import { selectFlyToMapPosition, selectMapBounds } from '../../../store/App/selectors';
import { selectBeingMobileSentinelSearch, selectIsMobileInteractionEnabled } from '../../../store/Map/Mobile/selectors';
import { actionSentinelMapZoom, actionSentinelSelectAOI } from '../../../store/Map/Sentinel/actions';

// Level to zoom to when focusing on a point
const FLYTO_ZOOM_LEVEL = 11;

// Force the map back to these bounds while panning
const WORLD_DRAG_REBOUND_BOUNDS: [number, number][] = [
    [-80.98155760646617, -220],
    [89.99346179538875, 240],
];

const MobileMapDispatcher = () => {
    const isMobileMapInteractionEnabled = useSelector(selectIsMobileInteractionEnabled);
    const beginMobileSentinelSearch = useSelector(selectBeingMobileSentinelSearch);
    const mapPosition = useSelector(selectFlyToMapPosition);
    const mapBounds = useSelector(selectMapBounds);

    const map = useMap();
    const dispatch = useDispatch();

    const setMapZoomLevel = useCallback(
        (zoom: number) => {
            dispatch(actionSetMapZoom(zoom));
            dispatch(actionSentinelMapZoom(zoom));
        },
        [dispatch]
    );

    const flyTo = useCallback(
        (position: LatLng | LatLngBounds) => {
            if (position instanceof LatLng) {
                const params = new URLSearchParams(window.location.search);
                const zoom = parseInt(params.get('pos')?.split(',')[2] || '');
                if (zoom && !Number.isNaN(zoom)) {
                    map.flyTo(position, zoom, { animate: false });
                } else {
                    map.flyTo(position, FLYTO_ZOOM_LEVEL, { animate: false });
                }
            } else if (position instanceof LatLngBounds) {
                map.flyToBounds(position, { animate: false });
            }
        },
        [map]
    );

    useEffect(() => {
        if (mapPosition) {
            dispatch(actionSetMapBounds(undefined));
            requestAnimationFrame(() => {
                flyTo(mapPosition);
            });
        }
    }, [dispatch, flyTo, map, mapPosition]);

    useEffect(() => {
        const setSentinelAOI = (bbox: LatLngBounds) => dispatch(actionSentinelSelectAOI(bbox));

        if (beginMobileSentinelSearch && map) {
            setSentinelAOI(map.getBounds());
            Analytics.Event('Mobile Map', 'Created sentinel AOI', map.getBounds());
        }
    }, [beginMobileSentinelSearch, dispatch, map]);

    useEffect(() => {
        if (!map) return;

        // Prevent the map from wrapping by forcing in to pan inside world bounds
        map.on('drag', () => {
            map.panInsideBounds(WORLD_DRAG_REBOUND_BOUNDS, { animate: false });
        });

        map.on('zoomend', () => {
            map.panInsideBounds(WORLD_DRAG_REBOUND_BOUNDS, { animate: false });
            setMapZoomLevel(map.getZoom());
            dispatch(actionSetMapBounds(map.getBounds()));
        });

        const handleMoveStart = () => {
            dispatch(actionSetMapMoveEnd(false));
        };

        const handleMoveEnd = () => {
            dispatch(actionSetMapMoveEnd(true));
            dispatch(actionSetMapBounds(map.getBounds()));
        };

        map.on('movestart', handleMoveStart);
        map.on('zoomstart', handleMoveStart);

        map.on('moveend', handleMoveEnd);
        map.on('zoomend', handleMoveEnd);

        dispatch(actionSetMapBounds(map.getBounds()));
        setMapZoomLevel(map.getZoom());
    }, [map, dispatch, setMapZoomLevel]);

    useEffect(() => {
        if (!map) return;

        // The mobile-map.tsx component gets reloaded every time the tabbar state is changed
        // This causes the maps bounds to reset to the default bounds, so we need to re-set them from redux state
        // Ideally, the map component would not be reloaded for tabbar state changes
        if (mapBounds && (mapBounds as LatLngBounds)) {
            map.flyToBounds(mapBounds as LatLngBounds, { animate: false });
        }

        if (isMobileMapInteractionEnabled) {
            map.dragging.enable();
            map.touchZoom.enable();
            map.doubleClickZoom.enabled();
            map.scrollWheelZoom.enabled();
            map.boxZoom.enabled();
            map.keyboard.enabled();
        } else {
            map.dragging.disable();
            map.touchZoom.disable();
            map.doubleClickZoom.disable();
            map.scrollWheelZoom.disable();
            map.boxZoom.disable();
            map.keyboard.disable();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMobileMapInteractionEnabled, map]);

    return <React.Fragment />;
};

export default MobileMapDispatcher;
