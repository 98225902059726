import React, { useEffect } from 'react';
import Discover from './Discover/discover';
import MobileMapContainer from './Map/mobile-map-container';
import MobileProfile from './Profile/mobile-profile';
import TabBar, { TabBarState } from './TabBar/tab-bar';
import { useMobileBrowserState } from './use-mobile-browser-state';
import MobileProfileView, { MobileProfileViewProps } from './Profile/mobile-profile-view';
import styled from 'styled-components';

interface MobileProps {
    tabBarState?: TabBarState;
    tabBarStateData?: MobileProfileViewProps;
}

const Mobile = (props: MobileProps) => {
    const { tabBarState, setTabBarState } = useMobileBrowserState();

    useEffect(() => {
        if (props.tabBarState) setTabBarState(props.tabBarState);
    }, [props.tabBarState, setTabBarState]);

    const renderTabBarState = () => {
        switch (tabBarState) {
            case 'maps':
            case 'map_view':
                return <MobileMapContainer tabBarState="maps" navigateToTab={setTabBarState} />;
            case 'satellites':
            case 'satellite_view':
            case 'drawProject':
                return <MobileMapContainer tabBarState={tabBarState} navigateToTab={setTabBarState} />;
            case 'discover':
                return <Discover />;
            case 'profile_view':
                return (
                    <MobileProfileView
                        profileId={props.tabBarStateData?.profileId ?? ''}
                        onClose={() => setTabBarState('maps')}
                    />
                );
            case 'profile':
            case 'profile_reset':
                return <MobileProfile />;
            default:
                return <React.Fragment />;
        }
    };

    return (
        <MobileContainer key={tabBarState}>
            {renderTabBarState()}
            <TabBar
                tabBarState={tabBarState ?? 'maps'}
                onTabBarStateChange={(newTabBarState) => setTabBarState(newTabBarState)}
            />
        </MobileContainer>
    );
};

export default Mobile;

const MobileContainer = styled.div``;
