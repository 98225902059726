import styled from 'styled-components';
import TabBarItem from './tab-bar-item';
import TabBarProfileItem from './tab-bar-profile-item';

export type TabBarState =
    | 'maps'
    | 'map_view'
    | 'satellites'
    | 'satellite_view'
    | 'discover'
    | 'profile'
    | 'profile_reset'
    | 'profile_view'
    | 'drawProject';

interface TabBarProps {
    tabBarState: TabBarState;
    onTabBarStateChange: (tabBarState: TabBarState) => void;
}

const TabBar = (props: TabBarProps) => {
    return (
        <TabBarContainer>
            <TabBarItem
                isActive={
                    props.tabBarState === 'maps' ||
                    props.tabBarState === 'map_view' ||
                    props.tabBarState === 'profile_view' ||
                    props.tabBarState === 'drawProject'
                }
                text="Maps"
                iconUrl="../../../assets/mobile/map-white.svg"
                activeIconUrl="../../../assets/mobile/map-yellow.svg"
                onClick={() => {
                    props.onTabBarStateChange('maps');
                }}
            />

            <TabBarItem
                isActive={props.tabBarState === 'satellites' || props.tabBarState === 'satellite_view'}
                text="Satellites"
                iconUrl="../../../assets/mobile/satellite-white.svg"
                activeIconUrl="../../../assets/mobile/satellite-yellow.svg"
                onClick={() => {
                    props.onTabBarStateChange('satellites');
                }}
            />

            <TabBarItem
                isActive={props.tabBarState === 'discover'}
                text="Discover"
                iconUrl="../../../assets/mobile/discover-white.svg"
                activeIconUrl="../../../assets/mobile/discover-yellow.svg"
                onClick={() => {
                    props.onTabBarStateChange('discover');
                }}
            />

            <TabBarProfileItem
                isActive={props.tabBarState === 'profile'}
                onClick={() => {
                    props.onTabBarStateChange('profile');
                }}
            />
        </TabBarContainer>
    );
};

export default TabBar;

const TabBarContainer = styled.div`
    z-index: 9999;
    background: black;
    position: fixed;
    width: 100vw;
    height: 64px;
    bottom: 0px;
    left: 0;
    right: 0;
    display: flex;
`;
