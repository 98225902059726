import React, { useEffect, useState } from 'react';

import BottomSheet, { BottomSheetState } from '../BottomSheet/bottom-sheet';
import MobileSoarLogo from '../mobile-soar-logo';
import MobileBasemapMenu from '../BaseMap/mobile-basemap-menu';
import MobileMap from './mobile-map';
import { TabBarState } from '../TabBar/tab-bar';
import MobileSatelliteRibbon from '../Satellite/mobile-satellite-ribbon';
import SentinelShareModal from '../../Drawer/Share/sentinel-share-modal';
import MapsRibbon from '../Maps/maps-ribbon';
import { useDispatch, useSelector } from 'react-redux';
import { selectActiveAllMap, selectActiveAllMapIsFetching } from '../../../store/Map/SuperMap/selectors';
import ActiveMapBottomSheet from './ActiveMap/active-map-bottom-sheet';
import { actionActiveMapCleared, actionActiveMapFetchById } from '../../../store/Map/SuperMap/actions';
import UriHelper from '../../../lib/uri-helper';
import { ListingDTOWithChip } from '../../Drawer/SideDrawer/ListingsDrawer/listing-card-chip';
import {
    actionSentinelClearFeatures,
    actionSentinelResetSelectedAOI,
    actionSentinelResetSelectedFeature,
} from '../../../store/Map/Sentinel/actions';
import SkeletonLoading from '../Shared/skeleton-loading';

interface MobileMapContainerProps {
    tabBarState: TabBarState;
    navigateToTab?: (tabBarState: TabBarState) => void;
}

const MobileMapContainer = (props: MobileMapContainerProps) => {
    const [bottomSheetState, setBottomSheetState] = useState(BottomSheetState.close);
    const [scrollEvent, setScrollEvent] = useState<React.UIEvent<HTMLDivElement> | undefined>(undefined);

    const dispatch = useDispatch();
    const activeMap = useSelector(selectActiveAllMap);
    const activeMapIsFetching = useSelector(selectActiveAllMapIsFetching);

    useEffect(() => {
        switch (props.tabBarState) {
            case 'maps':
                dispatch(actionSentinelResetSelectedFeature());
                dispatch(actionSentinelClearFeatures());
                dispatch(actionSentinelResetSelectedAOI());
                break;
            case 'satellites':
            case 'satellite_view':
                dispatch(actionActiveMapCleared());
                break;
            default:
                break;
        }
    }, [dispatch, props.tabBarState]);

    useEffect(() => {
        if (activeMapIsFetching) {
            setBottomSheetState(BottomSheetState.close);
        }
        if (activeMap) {
            setBottomSheetState(BottomSheetState.mid);
        }
    }, [activeMap, activeMapIsFetching]);

    const handleCloseMap = () => {
        dispatch(actionActiveMapCleared());

        switch (props.tabBarState) {
            case 'discover':
                UriHelper.navigateToPath('/discover');
                break;
            case 'maps':
            default:
                UriHelper.navigateToPath('/maps');
                break;
        }

        setBottomSheetState(BottomSheetState.close);
    };

    const handleSelectListing = (listing: ListingDTOWithChip) => {
        // TODO: Analytics
        // TODO: Add to history
        dispatch(actionActiveMapFetchById(listing.id));
        setBottomSheetState(BottomSheetState.close);
    };

    return (
        <React.Fragment>
            <MobileMap />
            <MobileSoarLogo />
            <MobileBasemapMenu />
            <BottomSheet
                onScroll={(e) => setScrollEvent(e)}
                setOpenState={bottomSheetState}
                onOpenStateChanged={(openState) => setBottomSheetState(openState)}
            >
                {(props.tabBarState === 'maps' || props.tabBarState === 'discover') && activeMapIsFetching ? (
                    <SkeletonLoading />
                ) : null}

                {(props.tabBarState === 'maps' || props.tabBarState === 'discover') &&
                !activeMapIsFetching &&
                activeMap ? (
                    <ActiveMapBottomSheet
                        bottomSheetState={bottomSheetState}
                        listing={activeMap}
                        isClosing={false}
                        onCloseMap={() => handleCloseMap()}
                    />
                ) : null}

                {props.tabBarState === 'maps' && !activeMapIsFetching && !activeMap ? (
                    <MapsRibbon
                        onOpenRibbon={() => setBottomSheetState(BottomSheetState.open)}
                        onCloseRibbon={() => setBottomSheetState(BottomSheetState.close)}
                        onSelectListing={handleSelectListing}
                        scrollEvent={scrollEvent}
                    />
                ) : null}

                {props.tabBarState === 'satellites' || props.tabBarState === 'satellite_view' ? (
                    <React.Fragment>
                        <MobileSatelliteRibbon
                            satelliteBottomSheetState={bottomSheetState}
                            setSatelliteBottomSheetState={setBottomSheetState}
                            navigateToTab={props.navigateToTab}
                        />
                        <SentinelShareModal />
                    </React.Fragment>
                ) : null}

                {
                    // TODO: View someones profile
                }
            </BottomSheet>
        </React.Fragment>
    );
};

export default MobileMapContainer;
