import React from 'react';
import styled from 'styled-components';
import { Button, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';

import { MobileButtonText } from '../../Shared/button';
import { StoaryResponse } from '../../../../api/stoaryModel';

interface MobileProfileStoariesControlProps {
    stoary: StoaryResponse;
    onOpen: () => void;
    onShare: () => void;
    onPermissions?: () => void;
    onEdit?: () => void;
    onDelete?: () => void;
}

const MobileProfileStoariesControl = (props: MobileProfileStoariesControlProps) => {
    return (
        <UncontrolledDropdown group className="stoary-control">
            <StoaryOpenButton onClick={props.onOpen}>
                <StoaryOpenButtonText>Open Stoary</StoaryOpenButtonText>
            </StoaryOpenButton>
            <StoaryToggleButton caret />
            <StoaryDropdownMenu>
                <StoaryShareButton onClick={props.onShare}>Share</StoaryShareButton>
                {props.onEdit && <StoaryEditButton onClick={props.onEdit}>Edit</StoaryEditButton>}
                {props.onPermissions && !props.stoary.sharedAt && (
                    <StoaryPermissionsButton onClick={props.onPermissions}>Permissions</StoaryPermissionsButton>
                )}
                {props.onDelete && !props.stoary.sharedAt && (
                    <React.Fragment>
                        <DropdownItem divider />
                        <StoaryDeleteButton onClick={props.onDelete}>Delete</StoaryDeleteButton>
                    </React.Fragment>
                )}
            </StoaryDropdownMenu>
        </UncontrolledDropdown>
    );
};

export default MobileProfileStoariesControl;

const StoaryOpenButtonText = styled.span`
    color: ${(props) => props.theme.color.white};
`;

const StoaryOpenButton = styled(Button)`
    background-color: ${(props) => props.theme.color.opaqueGray};
    display: flex;
    align-items: center;

    &:before {
        content: '';
        display: inline-block;
        width: 24px;
        height: 24px;

        background: url('/assets/annotations/project-dashboard/open_project.svg') no-repeat center;
        background-size: 18px;
    }

    ${StoaryOpenButtonText} {
        margin-left: 8px;
    }

    @media (max-width: 480px) {
        ${StoaryOpenButtonText} {
            display: none;
        }
    }
`;

const StoaryToggleButton = styled(DropdownToggle)`
    background-color: ${(props) => props.theme.color.opaqueGray};
    color: ${(props) => props.theme.color.white};
`;

const StoaryDropdownMenu = styled(DropdownMenu)`
    background-color: ${(props) => props.theme.color.opaqueGray};
    margin-right: 5px;
    width: 150px;
`;

const StoaryDropdownMenuItem = styled(MobileButtonText)`
    display: flex;
    color: ${(props) => props.theme.color.white};

    width: 100%;
    align-items: center;

    padding: 12px 18px;

    &:before {
        content: '';
        display: inline-block;
        width: 24px;
        height: 24px;
        background: transparent no-repeat center;
        background-size: 18px;

        margin-right: 10px;
    }
`;

const StoaryShareButton = styled(StoaryDropdownMenuItem)`
    &:before {
        background-image: url('/assets/annotations/project-dashboard/share.svg');
    }
`;

const StoaryEditButton = styled(StoaryDropdownMenuItem)`
    &:before {
        background-image: url('/assets/annotations/project-dashboard/edit_project.svg');
    }
`;

const StoaryPermissionsButton = styled(StoaryDropdownMenuItem)`
    &:before {
        background-image: url('/assets/annotations/project-dashboard/permissions.svg');
    }
`;

const StoaryDeleteButton = styled(StoaryDropdownMenuItem)`
    &:before {
        background-image: url('/assets/annotations/project-dashboard/delete.svg');
    }
`;
