import React, { useEffect, useRef, useState } from 'react';
import { useWindowHeight } from '@react-hook/window-size';
import { SwipeEventData, useSwipeable } from 'react-swipeable';
import styled from 'styled-components';
import { useMobilePadding } from './use-mobile-padding';

// Height the swipe container will occupy in the closed state
const CONTAINER_CLOSED_HEIGHT = 126; //px

// Height from the top the swipe container will occupy in the open state
const CONTAINER_OPEN_OFFSET_HEIGHT = 64; //px

// Above this speed the container will open immediately to the top or bottom depending on direction
const VELOCITY_SNAP = 1.5; // pixels per second

export enum BottomSheetState {
    open = 'open',
    mid = 'mid',
    close = 'close',
    transitioning = 'transitioning',
}

interface BottomSheetProps {
    children: React.ReactNode;
    onOpenStateChanged?: (openState: BottomSheetState) => void;
    setOpenState?: BottomSheetState;
    handleCloseButton?: () => void;
    showCloseButton?: boolean;
    onScroll?: (e: React.UIEvent<HTMLDivElement, UIEvent>) => void;
}

/**
 * This component replicates the native mobile experience of a Bottom Sheet (based off Google Maps).
 * It allows the user to:
 *  - Flick the sheet to open and close
 *  - Swipe to peek and set its height
 *  - Anchor the sheet at bottom, mid and top snap points
 *  - When fully open, scroll up through the content
 *  - When fully open, scroll down until the top of the content is reached and then swipe down to dismiss
 *
 *  *IMPORTANT*
 *  Pointer events are disabled by this component to support scrolling.
 *  Any children who require interaction (click, tap, etc) must have the following CSS applied:
 *
 *      `pointer-events: auto;`
 *
 * @param {(openState: BottomSheetState) => void} props.onOpenState is called when the sheet changes to an anchor point
 * @param {(BottomSheetState)} props.setOpenState can be used to set the sheets anchor point
 * @param {(void)} props.handleCloseButton on click event closes the draw which can be used within the child component to perform an action
 * @returns
 */
const BottomSheet = (props: BottomSheetProps) => {
    const screenHeight = useWindowHeight();
    const closeHeight = CONTAINER_CLOSED_HEIGHT;
    const openHeight = screenHeight - CONTAINER_OPEN_OFFSET_HEIGHT; //px;
    const midHeight = (openHeight + closeHeight) * 0.33;

    const [containerHeight, setContainerHeight] = useState(closeHeight);
    const [previousContainerHeight, setPreviousContainerHeight] = useState(closeHeight);
    const [isAtTop, setIsAtTop] = useState(false);

    const [bodyScrollPosition, setBodyScrollPosition] = useState(0);
    const bodyRef = useRef<HTMLDivElement | null>(null);

    //External update of height
    useEffect(() => {
        if (props.setOpenState) {
            switch (props.setOpenState) {
                case BottomSheetState.open:
                    requestAnimationFrame(() => setContainerHeight(openHeight));
                    setPreviousContainerHeight(openHeight);
                    setIsAtTop(true);
                    break;
                case BottomSheetState.mid:
                    requestAnimationFrame(() => setContainerHeight(midHeight));
                    setPreviousContainerHeight(midHeight);
                    setIsAtTop(false);
                    break;
                case BottomSheetState.close:
                    requestAnimationFrame(() => setContainerHeight(closeHeight));
                    setPreviousContainerHeight(closeHeight);
                    setIsAtTop(false);
                    break;
                default:
                    break;
            }
        }
    }, [closeHeight, midHeight, openHeight, props.setOpenState]);

    // Inform children of state change
    useEffect(() => {
        if (props.onOpenStateChanged) {
            if (containerHeight === openHeight) {
                props.onOpenStateChanged(BottomSheetState.open);
            } else if (containerHeight === midHeight) {
                props.onOpenStateChanged(BottomSheetState.mid);
            } else if (containerHeight === closeHeight) {
                props.onOpenStateChanged(BottomSheetState.close);
            } else {
                props.onOpenStateChanged(BottomSheetState.transitioning);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [closeHeight, containerHeight, midHeight, openHeight]);

    // Force the body to scroll to top when rendered.
    useEffect(() => {
        if (bodyRef && bodyRef.current) {
            bodyRef.current.scrollTo(0, 0);
        }
    }, [bodyRef]);

    const handleSwipeEnd = (e: SwipeEventData) => {
        //User is swiping up quickly, snap to full open
        if (e.dir === 'Up' && e.velocity > VELOCITY_SNAP) {
            setIsAtTop(true);
            requestAnimationFrame(() => setContainerHeight(openHeight));
            setPreviousContainerHeight(openHeight);
            return;
        }

        // User is swiping down quickly, snap to bottom
        if (e.dir === 'Down' && e.velocity > VELOCITY_SNAP && bodyScrollPosition <= 0) {
            setIsAtTop(false);
            requestAnimationFrame(() => setContainerHeight(closeHeight));
            setPreviousContainerHeight(closeHeight);
            return;
        }

        // User is swiping slowly, snap to nearest once released
        const deltaTop = Math.abs(containerHeight - openHeight);
        const deltaMid = Math.abs(containerHeight - midHeight);
        const deltaBottom = Math.abs(containerHeight - closeHeight);

        if (deltaTop < deltaMid && deltaTop < deltaBottom) {
            // Closest to fully open
            requestAnimationFrame(() => setContainerHeight(openHeight));
            setPreviousContainerHeight(openHeight);
            setIsAtTop(true);
        } else if (deltaMid < deltaTop && deltaMid < deltaBottom) {
            // Closest to mid snap point
            requestAnimationFrame(() => setContainerHeight(midHeight));
            setPreviousContainerHeight(midHeight);
            setIsAtTop(false);
        } else if (deltaBottom < deltaTop && deltaBottom < deltaMid) {
            // Closest to bottom
            requestAnimationFrame(() => setContainerHeight(closeHeight));
            setPreviousContainerHeight(closeHeight);
            setIsAtTop(false);
        }
    };

    const swipeHandler = useSwipeable({
        preventDefaultTouchmoveEvent: isAtTop,
        delta: 0,
        onSwiped: (e: SwipeEventData) => {
            setPreviousContainerHeight(containerHeight);
            handleSwipeEnd(e);
        },
        onSwiping: (e: SwipeEventData) => {
            const target = e.event.target as HTMLElement;
            if (target.getAttribute('type') === 'range') {
                return;
            }

            if (e.dir === 'Up') {
                const newHeight = Math.min(previousContainerHeight - e.deltaY, openHeight);
                setContainerHeight(newHeight);
            }

            if (e.dir === 'Down') {
                if (isAtTop) {
                    if (bodyScrollPosition <= 0) {
                        setIsAtTop(false);
                    }
                } else {
                    const newHeight = Math.max(previousContainerHeight - e.deltaY, closeHeight);
                    setContainerHeight(newHeight);
                }
            }
        },
    });

    const swipeBarHandler = useSwipeable({
        delta: 0,
        onSwiped: (e: SwipeEventData) => {
            setPreviousContainerHeight(containerHeight);
            handleSwipeEnd(e);
            if (bodyRef && bodyRef.current) {
                bodyRef.current.scrollTo(0, 0);
            }
        },
        onSwiping: (e: SwipeEventData) => {
            const target = e.event.target as HTMLElement;
            if (target.getAttribute('type') === 'range') {
                return;
            }

            if (e.dir === 'Down') {
                setIsAtTop(false);
                const newHeight = Math.max(previousContainerHeight - e.deltaY, closeHeight);
                setContainerHeight(newHeight);
            }
        },
    });

    return (
        /** @ts-ignore */
        <Container ref={bodyRef} transform={`translate(0, ${screenHeight - containerHeight}px)`} {...swipeHandler}>
            <Content data-testid="mobile-bottomsheet" containerHeight={useMobilePadding(bodyRef, containerHeight)}>
                <SwipeBarHandle {...swipeBarHandler}>
                    <SwipeBar {...swipeBarHandler} data-testid="mobile-bottomsheet-handle" />
                    {props.handleCloseButton && !props.showCloseButton ? (
                        <CloseIcon
                            data-testid="mobile-bottomsheet-close-button"
                            src="/assets/close.png"
                            onClick={() => props.handleCloseButton && props.handleCloseButton()}
                        />
                    ) : null}
                </SwipeBarHandle>
                <Body
                    data-testid="mobile-bottomsheet-content"
                    allowScroll={isAtTop}
                    onScroll={(e: React.UIEvent<HTMLDivElement, UIEvent>) => {
                        setBodyScrollPosition(e.currentTarget.scrollTop);
                        props.onScroll && props.onScroll(e);
                    }}
                >
                    <BodyIgnoreTouchEvent>{props.children}</BodyIgnoreTouchEvent>
                </Body>
            </Content>
        </Container>
    );
};

export default BottomSheet;

interface ContainerProps {
    transform: number;
}

const Container = styled.div.attrs<ContainerProps>(({ transform }) => ({
    style: { transform: transform },
}))<ContainerProps>`
    position: fixed;
    width: 100vw;
    background: rgba(34, 34, 34, 1);
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;

    overscroll-behavior-y: none !important;
    transition-delay: 0;
    transition-duration: 100ms;
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
    transition: none;

    -webkit-transition-delay: 0;
    -webkit-transition-duration: 1ms;
    -webkit-transition-property: transform;
    -webkit-transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
    -webkit-transition: none;

    // Some hacks to try and force GPU rendering
    will-change: transform !important;
`;

const SwipeBarHandle = styled.div`
    position: relative;
    margin: 0px auto;
    padding: 7px 5px 6px 5px;
    width: 65px;
`;

const SwipeBar = styled.div`
    width: 44px;
    height: 3px;
    border-radius: 2px;
    margin: 0px auto;
    display: block;
    background: #ccc;
    border: 1px solid rgba(0, 0, 0, 0.2);
`;

interface BodyProps {
    allowScroll: boolean;
}

const Body = styled.div.attrs<BodyProps>(({ allowScroll }) => ({
    style: { overflowY: allowScroll ? 'scroll' : 'hidden' },
}))<BodyProps>`
    height: 100%;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    pointer-events: default;
    touch-action: auto;
`;

// If we don't ignore touch events on the scrolling content, it won't allow swipes on the bottom sheet itself
// Eg. swipes won't work on content, only the div
// The downside is all children elements that explicity require interaction need to set their pointer-event
const BodyIgnoreTouchEvent = styled.div`
    pointer-events: none;
`;

interface ContentProps {
    containerHeight: number;
}

const Content = styled.div<ContentProps>`
    height: ${(props) => (props.containerHeight ? `${props.containerHeight}px` : '100vh')};
    padding-bottom: 128px; // Base padding 32 + SwipeBar 16 + TabBar 76px
`;

const CloseIcon = styled.img`
    margin: 8px;
    width: 22px;
    height: 22px;
    cursor: pointer;
    pointer-events: all;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 9999;
`;
