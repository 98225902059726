import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router';
import { selectLoggedIn } from './store/Account/selectors';
import { actionFlyTo } from './store/App/actions';
import { selectConfig } from './store/App/selectors';
import { actionActiveMapCleared, actionActiveMapFetchById } from './store/Map/SuperMap/actions';
import { SideDrawerMode } from './store/SideDrawer/model';
import SoarHelper from './lib/soar-helper';
import UriHelper, { usePositionQuery } from './lib/uri-helper';

import JoinMobile from './components/LandingPage/join';
import MobileViewStoryMaps from './components/Mobile/Map/Annotations/StoryMaps/mobile-view-story-maps';
import Mobile from './components/Mobile/mobile';
import RedirectPage from './components/redirect-page';
import LoginRegister from './components/Registration/index';
import { LoginModalMode } from './components/Registration/login-enum';
import LoginRegisterDialog from './components/Registration/login-register-dialog';
import OAuthCallback from './components/Registration/OAuth2/Shared/oauth-callback';
import Mobile404 from './components/Mobile/mobile-404';
import MobileAccess from './components/Mobile/Profile/Access/mobile-access';

const MobileRouteMap = () => {
    const config = useSelector(selectConfig);
    const [position, zoom] = usePositionQuery();

    const dispatch = useDispatch();

    const userLoggedIn = useSelector(selectLoggedIn);

    const handlePositionQueryParameter = useCallback(
        (immediately: boolean) => {
            if (position && zoom) {
                dispatch(actionFlyTo(position, zoom, immediately));
            }
        },
        [position, zoom, dispatch]
    );

    // A link was followed to an explicit location and zoom level eg.  ?pos=-31.22,114.3,3
    useEffect(() => {
        handlePositionQueryParameter(true);
    }, [handlePositionQueryParameter]);

    const clearMap = () => {
        dispatch(actionActiveMapCleared());
    };

    if (config.BEHAVIOURS.FORCED_LOGIN) {
        if (!userLoggedIn) {
            return <Route render={(_) => <LoginRegister subDomain={config.SUBDOMAIN} />} />;
        }
    }

    if (!userLoggedIn) {
        return (
            <Switch>
                <Route exact path="/auth/callback" component={OAuthCallback} />
                <Route
                    exact
                    path="/password_reset"
                    render={(_) => {
                        return <Mobile tabBarState="profile_reset" />;
                    }}
                />
                <Route render={(_) => <MobileAccess />} />
            </Switch>
        );
    }

    return (
        <Switch>
            <Route
                exact
                path="/"
                render={(_) => {
                    return <Mobile tabBarState="maps" />;
                }}
            />

            <Route
                exact
                path="/maps"
                render={(_) => {
                    return <Mobile tabBarState="maps" />;
                }}
            />

            <Route
                exact
                path="/maps/:slug"
                render={(props: RouteComponentProps<{ slug: string }>) => {
                    handlePositionQueryParameter(true);
                    clearMap();
                    const tileLayerId = SoarHelper.extractIdFromMapSlug(props.match.params.slug);
                    if (tileLayerId) {
                        dispatch(actionActiveMapFetchById(tileLayerId, zoom));
                    }
                    return <Mobile tabBarState="map_view" />;
                }}
            />

            <Route
                exact
                path="/discover"
                render={(_) => {
                    return <Mobile tabBarState="discover" />;
                }}
            />

            <Route
                exact
                path="/draw/:projectId"
                render={(props: RouteComponentProps<{ projectId: string }>) => {
                    const { projectId } = props.match.params;
                    UriHelper.removeParameterFromUri('pos');
                    return (
                        <MobileViewStoryMaps
                            drawProjectId={Number(projectId)}
                            drawerMode={SideDrawerMode.SOAR_PROJECT}
                        />
                    );
                }}
            />
            <Route exact path="/satellites/sentinel" render={(_) => <Mobile tabBarState="satellite_view" />} />
            <Route exact path="/satellites/landsat" render={(_) => <Mobile tabBarState="satellite_view" />} />

            <Route exact path="/satellites" render={(_) => <Mobile tabBarState="satellites" />} />

            <Redirect exact path="/mobile/sentinel" to="/satellites" />

            <Redirect exact path="/dashboard" to="/profile" />
            <Redirect exact path="/upload" to="/profile" />

            <Route path="/join" render={(_) => <JoinMobile />} />

            <Route
                exact
                path="/profile"
                render={(_) => {
                    return <Mobile tabBarState="profile" />;
                }}
            />
            <Route
                exact
                path="/profile/:profileId"
                render={(props: RouteComponentProps<{ profileId: string }>) => {
                    const { profileId } = props.match.params;

                    if (!profileId) {
                        return <Redirect to={{ pathname: '/maps' }} />;
                    }

                    return <Mobile tabBarState="profile_view" tabBarStateData={{ profileId: profileId }} />;
                }}
            />

            <Route
                exact
                path="/login"
                render={(_) => (
                    <LoginRegisterDialog
                        isOpen={true}
                        initialMode={LoginModalMode.LOGIN}
                        onClose={() => UriHelper.navigateToPath('/')}
                    />
                )}
            />
            <Route
                exact
                path="/register"
                render={(_) => (
                    <LoginRegisterDialog
                        isOpen={true}
                        initialMode={LoginModalMode.REGISTER}
                        onClose={() => UriHelper.navigateToPath('/')}
                    />
                )}
            />
            <Route
                exact
                path="/password_reset"
                render={(_) => {
                    return <Mobile tabBarState="profile_reset" />;
                }}
            />
            <Route
                exact
                path="/forgot_password"
                render={(_) => (
                    <LoginRegisterDialog
                        isOpen={true}
                        initialMode={LoginModalMode.FORGOT_PASSWORD}
                        onClose={() => UriHelper.navigateToPath('/')}
                    />
                )}
            />
            <Route exact path="/auth/callback" component={OAuthCallback} />
            <Route exact path="/terms" render={(_) => <RedirectPage url={`https://about.soar.earth/terms`} />} />
            <Route exact path="/privacy" render={(_) => <RedirectPage url={`https://about.soar.earth/privacy`} />} />
            <Route path="/" render={(_) => <Mobile404 />} />
        </Switch>
    );
};

export default MobileRouteMap;
