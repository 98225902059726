import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { keyframes } from 'styled-components';
import ApiSocial from '../../../../api/api-social';
import { ListingDTO } from '../../../../api/model';
import Analytics from '../../../../lib/user-analytics';
import { selectLoggedIn } from '../../../../store/Account/selectors';
import { actionShowLoginDialog } from '../../../../store/App/actions';
import { actionFetchBookmarkedMaps } from '../../../../store/Map/BookMarks/actions';
import { selectBookmarkedMaps } from '../../../../store/Map/BookMarks/selectors';
import RemoveBookmarkModal from '../../../Shared/Modal/remove-bookmark';
import { PulseLoader } from '../../../Shared/pulse-loader';

interface ActiveMapBookmarkButtonProps {
    listing: ListingDTO;
}

const ActiveMapBookmarkButton = (props: ActiveMapBookmarkButtonProps) => {
    const [isRemovingBookmark, setIsRemovingBookmark] = useState(false);
    const [bookmarkBusy, setBookmarkBusy] = useState(false);

    const isLoggedIn = useSelector(selectLoggedIn);

    const dispatch = useDispatch();
    const bookmarkedMaps = useSelector(selectBookmarkedMaps);

    const isBookMarked = bookmarkedMaps?.find((t) => t.id === props.listing.id) ? true : false;

    const handleClickedBookmark = async () => {
        if (!isLoggedIn) {
            dispatch(actionShowLoginDialog(true));
            return;
        }

        setBookmarkBusy(true);
        bookmarkActiveMap().finally(() => setBookmarkBusy(false));
    };

    const bookmarkActiveMap = async () => {
        if (isBookMarked) {
            if (!isRemovingBookmark) {
                setIsRemovingBookmark(true);
                return;
            }

            Analytics.Event('Mobile', 'Clicked Remove Bookmark', props.listing.id);
            await ApiSocial.removeBookmarkListing(props.listing.id);
            await dispatch(actionFetchBookmarkedMaps());
            setIsRemovingBookmark(false);
            return;
        }

        Analytics.Event('Mobile', 'Clicked Add Bookmark', props.listing.id);
        await ApiSocial.addBookmarkListing(props.listing.id);
        await dispatch(actionFetchBookmarkedMaps());
    };

    useEffect(() => {
        if (!bookmarkedMaps && isLoggedIn) {
            dispatch(actionFetchBookmarkedMaps());
        }
    }, [bookmarkedMaps, dispatch, isLoggedIn]);

    return (
        <React.Fragment>
            <Button onClick={handleClickedBookmark} disabled={bookmarkBusy}>
                <Icon
                    title="Bookmark Map"
                    onClick={handleClickedBookmark}
                    className={isBookMarked ? 'fa fa-bookmark bookmarked' : 'fa fa-bookmark-o'}
                    data-testid="map-bookmark"
                />
                <Text>{bookmarkBusy ? <PulseLoader /> : isBookMarked ? 'Remove Bookmark' : 'Bookmark'}</Text>
            </Button>

            {isRemovingBookmark && (
                <RemoveBookmarkModal
                    isOpen={true}
                    isBusy={bookmarkBusy}
                    onRemove={() => handleClickedBookmark()}
                    toggle={() => setIsRemovingBookmark(false)}
                />
            )}
        </React.Fragment>
    );
};

export default ActiveMapBookmarkButton;

const BookmarkAnimation = keyframes`
  0%, 10%, 43%, 70%, 100% {
    transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 0.3);
    transform: translate3d(0, 0, 0);
  }
  30%, 33% {
    transition-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
    transform: translate3d(0, -10px, 0);
  }
  60% {
    transition-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
    transform: translate3d(0, -5px, 0);
  }
  80% {
    transform: translate3d(0, -2px, 0);
  }
`;

const Button = styled.button`
    background: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(5px);
    border: 1px solid rgba(142, 145, 143, 0.3);
    border-radius: 6px;
    height: 40px;
    letter-spacing: 0.25px;
    min-width: 40px;
    margin: 5px;
    outline: none !important;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 100%;
    pointer-events: auto;
    display: flex;
    flex-direction: row;
`;

const Icon = styled.i`
    color: white;
    font-size: 20px;
    height: 20px;
    margin: 8px 5px 5px 5px;
    pointer-events: auto;

    &.bookmarked {
        animation: ${BookmarkAnimation} 0.75s;
        animation-iteration-count: 1;
        color: #eed926;
    }

    &:hover {
        color: #eed926;
    }
`;

const Text = styled.span`
    font-size: 16px;
    color: white;
    margin: 6px 0 0 0;
`;
