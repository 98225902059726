import styled from 'styled-components';
import SkeletonLoading from '../Shared/skeleton-loading';

interface MapsRibbonListingCountProps {
    listingLocationCount?: number | undefined;
    onClick: () => void;
}

const MapsRibbonListingCount = (props: MapsRibbonListingCountProps) => {
    if (props.listingLocationCount === undefined) {
        return <SkeletonLoading />;
    }

    return (
        <Container>
            <ListingCountContainer onClick={props.onClick}>
                {`${
                    props.listingLocationCount
                        ? `${props.listingLocationCount.toLocaleString()} ${
                              props.listingLocationCount === 1 ? 'map' : 'maps'
                          } in this area`
                        : 'No maps in this area'
                }`}
            </ListingCountContainer>
        </Container>
    );
};

export default MapsRibbonListingCount;

const Container = styled.div`
    background: rgb(34, 34, 34);
    position: absolute;
    width: 100%;
    height: 49px;
    margin-top: -15px;
    z-index: 1;
    opacity: 1;
    pointer-events: auto;
`;

const ListingCountContainer = styled.div`
    background: rgba(255, 255, 255, 0.05);
    border-radius: 6px;
    transition: opacity 0.3s ease, transform 0.3s ease;
    font-size: 16px;
    color: white;
    margin: 0 0 0 15px;
    padding: 5px;
    text-align: center;
    pointer-events: auto;
    width: calc(100% - 30px);
`;
