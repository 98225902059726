import styled from 'styled-components';
import moment from 'moment';

import { ListingDTO, ListingType, UserDTO } from '../../../api/model';
import UserAvatar from '../../Shared/Avatar/user-avatar';
import ProfileStats from '../../Drawer/SideDrawer/ProfileDrawer/Profile/profile-stats';
import { MarkdownViewer } from '../../Shared/markdown-viewer';
import CollapsibleText from './Shared/collapsible-text';
import MobileViewDetailsShare from './View/mobile-details-share';
import PointsUtil from '../../NavigationBar/Points/points-util';

interface MobileProfileDetailsProps {
    profile: UserDTO;
    listings: ListingDTO[];
    avatarPreview?: string;
    showAvatarPreview?: (preview: string) => void;
}

const MobileProfileDetails = (props: MobileProfileDetailsProps) => {
    const approvedListings = props.listings.filter(
        (listing) =>
            listing.review === 'APPROVED' &&
            listing.listingType !== ListingType.ORDER &&
            listing.listingType !== ListingType.NOT_SUPPORTED
    );

    return (
        <ProfileDetails>
            <ProfileDetailsHeader>
                <ProfileAvatarContainer>
                    {props.avatarPreview ? (
                        <ProfileAvatarPreview alt="Avatar Preview" src={props.avatarPreview} />
                    ) : (
                        <ProfileAvatar user={props.profile} margin="0px 0px 0px 0px" diameter={80} />
                    )}
                    <ProfilePoints title={`${props.profile.name} has ${props.profile.points?.toLocaleString()} points`}>
                        {PointsUtil.pointsFormatter(props.profile.points)} points
                    </ProfilePoints>
                </ProfileAvatarContainer>
                <ProfileStats userProfile={props.profile} listings={approvedListings ?? []} />
            </ProfileDetailsHeader>
            {props.profile?.bio && (
                <ProfileDetailsContent>
                    <ProfileDetailsContentHeader>Tagline</ProfileDetailsContentHeader>
                    <ProfileDetailsContentContent>{props.profile.bio}</ProfileDetailsContentContent>
                </ProfileDetailsContent>
            )}
            {props.profile?.longDescription && (
                <ProfileDetailsContent>
                    <ProfileDetailsContentHeader>About</ProfileDetailsContentHeader>
                    <ProfileBioText>
                        <MarkdownViewer text={props.profile.longDescription || ''} />
                    </ProfileBioText>
                </ProfileDetailsContent>
            )}
            <ProfileDetailsControls>
                <MobileViewDetailsShare userProfile={props.profile} />
            </ProfileDetailsControls>
            {props.profile.createdAt && (
                <ProfileDetailsCreated>
                    Member Since {moment((props.profile.createdAt || 0) * 1000).format('D MMM YYYY')}
                </ProfileDetailsCreated>
            )}
        </ProfileDetails>
    );
};

export default MobileProfileDetails;

const ProfileDetails = styled.div`
    flex: 0 1 auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;

    padding: 16px;

    border-bottom: 1px solid ${(props) => props.theme.color.lightGray};

    margin-top: 60px;
`;
const ProfileDetailsHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 8px 0;
`;

const ProfileAvatarContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const ProfileAvatarPreview = styled.img`
    width: 80px;
    height: 80px;
    border-radius: 50%;
    border: 2px solid ${(props) => props.theme.color.yellow};
    margin: 0px;
`;

const ProfileAvatar = styled(UserAvatar)``;

const ProfilePoints = styled.span`
    color: ${(props) => props.theme.color.yellow};
    font-size: ${(props) => props.theme.fontSize.small};
    font-weight: 700;
    text-align: center;
`;

const ProfileDetailsContent = styled.div`
    display: flex;
    flex-direction: column;

    padding: 8px 0 16px;
`;
const ProfileDetailsContentHeader = styled.h3`
    color: ${(props) => props.theme.color.white};
    font-size: ${(props) => props.theme.fontSize.normal};
    font-weight: bold;

    text-align: left;
`;
const ProfileDetailsContentContent = styled.div`
    color: ${(props) => props.theme.color.white};
    font-size: ${(props) => props.theme.fontSize.normal};
    overflow-wrap: break-word;

    text-align: left;
`;

const ProfileBioText = styled(CollapsibleText)`
    margin-bottom: 16px;
`;

const ProfileDetailsControls = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;

    padding: 8px 16px;
`;

const ProfileDetailsCreated = styled.div`
    font-size: ${(props) => props.theme.fontSize.normal};
    color: ${(props) => props.theme.color.opaqueWhite};
    text-align: center;
    padding-bottom: 20px;
`;
