import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import {
    actionSentinelChangeOpacity,
    actionSentinelOpenShare,
    actionSentinelSelectFeatureOpacity,
} from '../../../store/Map/Sentinel/actions';
import { SentinelFeature } from '../../../store/Map/Sentinel/model';
import {
    selectSentinelSelectedFeatureOpacity,
    selectSentinelOpacity,
    selectSentinelError,
} from '../../../store/Map/Sentinel/selectors';
import React from 'react';
import UserHelper from '../../../lib/user-helper';
import Analytics from '../../../lib/user-analytics';
import { StyledButton } from '../../Shared/styled-button';

const moment = require('moment');

interface SentinelItemProps {
    feature: SentinelFeature;
}

const MobileSentinelSearchResult = (props: SentinelItemProps) => {
    const sentinelSelectedFeatureOpacity = useSelector(selectSentinelSelectedFeatureOpacity);
    const sentinelOpacity = useSelector(selectSentinelOpacity);
    const sentinelError = useSelector(selectSentinelError);

    const dispatch = useDispatch();
    const changeOpacity = (opacity: number) => {
        dispatch(actionSentinelChangeOpacity({ id: props.feature.id, opacity }));
        dispatch(actionSentinelSelectFeatureOpacity(opacity));
    };
    const openShare = () => dispatch(actionSentinelOpenShare());

    const opacity = sentinelOpacity[props.feature.id] || 1.0;

    // this should set the selected satellite's opacity when exiting from draw tools
    useEffect(() => {
        if (sentinelSelectedFeatureOpacity && !sentinelOpacity[props.feature.id]) {
            dispatch(actionSentinelChangeOpacity({ id: props.feature.id, opacity: sentinelSelectedFeatureOpacity }));
        }
    }, [dispatch, sentinelSelectedFeatureOpacity, props.feature.id, sentinelOpacity]);

    return (
        <SentinelResultsContainer>
            <SliderDetailRow>
                <DetailName>Transparency: </DetailName>
                <DetailValue className="slider-detail-value">
                    <Slider
                        percentageFilled={opacity * 100}
                        type="range"
                        defaultValue={100}
                        value={opacity * 100}
                        step={0.01}
                        onClick={(e) => e.stopPropagation()}
                        onChange={(e) => {
                            const opacity = parseInt(e.target.value) / 100;
                            changeOpacity(opacity || 0.0001);
                        }}
                    />
                </DetailValue>
            </SliderDetailRow>
            {props.feature ? (
                <React.Fragment>
                    <Divider />
                    <Description>
                        <DescriptionText>
                            <strong>Satellite Provider:</strong> {props.feature.satellite} (
                            {props.feature.satelliteAnalytics})
                        </DescriptionText>
                        <DescriptionText>
                            <strong>Date Captured:</strong> {moment(props.feature.date).format('MMMM Do, YYYY')}
                        </DescriptionText>
                        <DescriptionText>
                            <strong>Type:</strong> {UserHelper.handleSatelliteLayerName(props.feature.layer)}
                        </DescriptionText>
                        <DescriptionText>
                            <strong>Resolution:</strong> {props.feature.resolution}/pixel
                        </DescriptionText>
                    </Description>
                    <ShareButton
                        title="Share"
                        onClick={() => {
                            openShare();
                            Analytics.Event('Mobile - Sentinel', 'Clicked share', props.feature.bbox.toBBoxString());
                        }}
                        disabled={sentinelError ? true : false}
                    >
                        Share Image
                    </ShareButton>
                </React.Fragment>
            ) : null}
        </SentinelResultsContainer>
    );
};

export default MobileSentinelSearchResult;

const SentinelResultsContainer = styled.div`
    user-select: none;
    margin: 5px 10px;
`;

const Divider = styled.div`
    border-bottom: 1px solid ${(props) => props.theme.color.mobile.divider};
    margin: 20px 0px;
`;

const Description = styled.div`
    margin: 10px 0px;
`;

const DescriptionText = styled.p`
    color: ${(props) => props.theme.color.white};

    strong {
        color: inherit;
    }
`;

interface SliderProps {
    percentageFilled: number;
}

const SliderDetailRow = styled.div`
    user-select: none;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const DetailName = styled.span`
    color: ${(props) => props.theme.color.white};
    width: 100px;
    flex-shrink: 0;
    font-size: ${(props) => props.theme.fontSize.normal};
`;

const DetailValue = styled.span`
    color: ${(props) => props.theme.color.white};
    flex-grow: 1;
    display: flex;
    align-items: center;
`;

const Slider = styled.input<SliderProps>`
    background-color: ${(props) => props.theme.color.yellow};
    background: ${(props) =>
        `linear-gradient(to right, 
            ${props.theme.color.yellow} 0%, 
            ${props.theme.color.yellow} ${props.percentageFilled}%, 
            #999 ${props.percentageFilled}%, 
            #999 100%);`};
    width: 100%;
    height: 2px;
    margin: 0;
    box-shadow: none !important;
    border: none !important;
    -webkit-appearance: none;
    outline: none;
    border-radius: ${(props) => props.theme.borderRadius};
    cursor: pointer;

    &::-webkit-slider-thumb {
        appearance: none;
        -webkit-appearance: none;
        background: ${(props) => props.theme.color.yellow};
        height: 12px;
        width: 12px;
        border-radius: 50%;
    }

    &:hover::-webkit-slider-thumb {
        appearance: none;
        -webkit-appearance: none;
        background: ${(props) => props.theme.color.yellow};
        border-radius: 50%;
        cursor: pointer;
        -webkit-box-shadow: 0px 0px 10px 2px rgba(238, 217, 38, 0.69);
        -moz-box-shadow: 0px 0px 10px 2px rgba(238, 217, 38, 0.69);
        box-shadow: 0px 0px 10px 2px rgba(238, 217, 38, 0.69);
    }
`;

const ShareButton = styled(StyledButton)`
    margin: 10px auto;
`;
